import React from 'react'

const StarRating = ({ score, ...props }) => {
  score = Math.round(score)
  var stars = [...Array(5).keys()]

  return (
    <div className='flex mr-2'>
      {stars.map((star, i) => (
        <div key={i} className={`text-purple ${score >= (i + 1) ? 'opacity-100' : 'opacity-25'}`}>&#9733;</div>
      ))}
    </div>
  )
}

export default StarRating

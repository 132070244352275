import React, { useState, useEffect } from 'react'
import StarRating from './star-rating'
import Carousel from './carousel'
import scrollTo from 'gatsby-plugin-smoothscroll'

/**
 * Fetch and display reviews from stamped.io
 */
const Reviews = ({
  productId = '',
  title = '',
  preTitle = '',
  featuredOnly,
  compact = false,
  showTotals = false,
  ...props
}) => {
  const [reviewsData, setReviewsData] = useState(undefined)
  const [reviewsTotal, setReviewsTotal] = useState(undefined)
  const storeUrl = 'maja.no'
  const apiKey = 'pubkey-9ZMnaBr7CFv30Q9S6QKTM4mmH1437y'

  useEffect(() => {
    window.fetch(`https://stamped.io/api/widget/reviews?productId=${productId}&productType&email&isWithPhotos&minRating&take&dateFrom&dateTo&sortReviews=featured&storeUrl=${storeUrl}&apiKey=${apiKey}`)
      .then(response => response.status !== 200 ? null : response)
      .then(response => response.json())
      .then(resultData => {
        if (featuredOnly && resultData.data) {
          resultData.data = resultData.data.filter(review => review.featured)
        }

        setReviewsData(resultData)
      })
      .catch(error => {
        // do nothing.
      })

    window.fetch(`https://stamped.io/api/widget/reviews?productId=${productId}&productType&email&isWithPhotos&minRating&take&dateFrom&dateTo&sortReviews=featured&storeUrl=${storeUrl}&apiKey=${apiKey}&type=site-badge`)
      .then(response => response.status !== 200 ? null : response)
      .then(response => response.json())
      .then(resultData => {
        setReviewsTotal(resultData)

        const template = `
          <div class="text-xs md:text-sm">
            <div class="inline-flex mr-2">
              <div class="text-purple opacity-100">★</div>
              <div class="text-purple opacity-100">★</div>
              <div class="text-purple opacity-100">★</div>
              <div class="text-purple opacity-100">★</div>
              <div class="text-purple opacity-100">★</div>
            </div>
            <button class="js-scroll-to-reviews underline">${resultData.total} har allerede anbefalt oss</a>
          </div>
        `

        // Only show if there are any reviews given.
        if (resultData && resultData.total >= 10) {
          const shortcuts = document.querySelectorAll('.js-reviews-shortcut')

          for (let i = 0; i < shortcuts.length; i++) {
            shortcuts[i].innerHTML = template
          }
        }

        const buttons = document.querySelectorAll('.js-scroll-to-reviews')

        for (let i = 0; i < buttons.length; i++) {
          buttons[i].addEventListener('click', (e) => {
            scrollTo('#anmeldelser')
          })
        }
      })
      .catch(error => {
        // do nothing.
      })
  }, [])

  // Don't show reviews if less than 5
  if (reviewsTotal && reviewsTotal.total < 5) {
    return null
  }

  return (
    <>
      {reviewsData && reviewsData.data.length && (
        <div {...props} id="anmeldelser">
          {preTitle && !showTotals && (
            <div className='text-xs text-center mb-2'>{preTitle}</div>
          )}

          {showTotals && reviewsTotal && (
            <div className='text-xs text-center mb-2 flex justify-center'>
              <StarRating score={reviewsTotal.rating} />
              {reviewsTotal.rating}/5 basert på {reviewsTotal.total} anmeldelser
            </div>
          )}

          {title && (
            <h2 className='font-bold text-purple font-serif text-center mb-12 md:mb-16 px-4'>{title}</h2>
          )}

          <Carousel
            className='relative pl-4 lg:pl-12 xl:pl-20'
            buttons={false}
            progressbar
          >
            {reviewsData.data.map((review, i) => (
              <div className='w-72 flex-shrink-0 pr-5' key={i}>
                <div className='h-full bg-white p-5 flex flex-col'>
                  <div className='text-xs sm-text-sm text-purple'><FormattedDate date={review.reviewDate} /></div>
                  <div className='mb-4'><StarRating score={review.reviewRating} /></div>
                  <div className='font-medium text-sm sm:text-base mb-2'>{review.reviewTitle}</div>
                  <p className='text-xs sm:text-sm'><TruncatedText text={review.reviewMessage} /></p>
                  <div className='font-bold text-primary text-xs sm:text-sm uppercase mt-auto'>{review.author}</div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  )
}

const TruncatedText = ({ text }) => {
  text = text.trim()

  const threshold = 133.7
  const length = text.length
  const [isTruncated, setTruncated] = useState(length > threshold)

  return (
    <>
      {isTruncated ? (
        <>
          {text.substring(0, threshold)}&nbsp;
          <button className='text-purple underline' onClick={(e) => {
            e.preventDefault()
            setTruncated(false)
          }}>les mer...</button>
        </>
      ) : text}
    </>
  )
}

const FormattedDate = ({ date }) => {
  const parts = date.split('.')
  const formattedDate = [parts[1],parts[0],parts[2]].join('.')

  return (
    formattedDate
  )
}

export default Reviews

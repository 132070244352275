import React from 'react'
import Figure from './figure'

const Usp = ({ text, image, compact = false, ...props }) => (
  <>
    {compact ? (
      <div className='flex items-center'>
        <div className='w-10 h-10 mr-4 rounded-full'>
          {image && image.asset && (
            <Figure node={image} />
          )}
        </div>

        {text}
      </div>
    ) : (
      <div className='flex items-center mt-6 mb-6 mr-16 lg:ml-8 lg:mr-8 paragraph-4 whitespace-no-wrap'>
        <div className='w-10 h-10 mr-4 rounded-full'>
          {image && image.asset && (
            <Figure node={image} />
          )}
        </div>

        {text}
      </div>
    )}
  </>
)

export default Usp

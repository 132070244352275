import React from 'react'
import Ticker from 'react-ticker'

const Marquee = ({}) => {
  return (
    <div className='bg-primary py-8'>
      <Ticker className='bg-primary'>
        {({ index }) => (
          <div className='flex font-serif h4 mb-0 whitespace-no-wrap text-yellow-lighter'>
            <div className='mr-8'>Mer frihet.</div>
            <div className='mr-8'>Ingen omveier.</div>
            <div className='mr-8'>Ingen ventetid.</div>
            <div className='mr-8'>Mer frihet.</div>
            <div className='mr-8'>Ingen omveier.</div>
            <div className='mr-8'>Til lavere pris.</div>
          </div>
        )}
      </Ticker>
    </div>
  )
}

export default Marquee
